import React from "react";
import { useHistory } from "react-router-dom";
import './Hero.scss';

const HomepageHero = (props) => {
    const heroStyleHome = {
        width: "100%",
        height: "550px",
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }

    const heroStyleSecondary = {
        width: "100%",
        height: "400px",
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }

    let history = useHistory();
    let pageType = ''
    history.location.pathname === "/" ? pageType = 'home' : pageType = 'secondary';

    return (
        <div>
            <div>
                <div className={`hero ${props.className}`} style={history.location.pathname === "/" ? heroStyleHome : heroStyleSecondary}>
                <img src="https://henrico-eda-site-assets.s3.us-east-1.amazonaws.com/portal-uploads/Henrico+Tomorrrow+Logo_White.png" className="henricoTomorrowLogo"/>
                    {/* <h1 className={`hero-title hero-title-${pageType}`}>{props.title}</h1> */}
                    <h1 className={`hero-title2 hero-title2-${pageType}`}>{"Groundbreaking office incentives to create the workplace of tomorrow"}</h1>
                    {
                        // history.location.pathname === "/" ? <button className="button-primary button-hero-home" onClick={() => {history.push('/find-a-property/innsbrook-technology-zone')}} >DOWNLOAD YOUR COPY</button> : ''
                        history.location.pathname === "/" ? <a href="https://www.henrico.com/target-sectors/corporate-and-regional-headquarters"><button className="button-primary button-hero-home orange">LEARN MORE</button></a> : ''
                    }
                    {props.heroCaption && (
                        <div className="hero-caption">{props.heroCaption}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomepageHero;
