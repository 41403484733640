import { Col, Container, Row } from "react-bootstrap";
import React from "react";
// import GisPropertySearchEmbed from '../GisPropertySearchEmbed/GisPropertySearchEmbed';
import PageTitle from '../PageTitle/PageTitle';
import './HenricoPropertySearch.scss';

const pageTitle = 'Henrico Property Search';

const HenricoPropertySearch = () => {
        return (
            <div>
                <Container>
                    <Col>
                        <PageTitle title={pageTitle} />
                    </Col>
                    <Row>
                        <p className="paragraph-header--blue">The info you need to make your business move</p>
                        <p>There’s no better place to expand or relocate your business than Henrico, Virginia. With our <a href="https://www.henrico.com/site-selectors/taxes" target="_self" rel="noopener noreferrer">low tax rates</a>, <a href="https://www.henrico.com/why-henrico/pro-business" target="_self" rel="noopener noreferrer">pro-business climate</a>, <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_self" rel="noopener noreferrer">skilled workforce</a>, and abundant options in <a href="https://www.henrico.com/find-a-property/featured-properties" target="_self" rel="noopener noreferrer">commercial real estate</a>, you can find the perfect site for your business. It’s why leading <a href="https://www.henrico.com/target-sectors/advanced-manufacturing" target="_self" rel="noopener noreferrer">advanced manufacturers</a> and Fortune 500 companies call Henrico home. 
                        </p>
                    </Row>
                    <Row>
                        <Col xs={12} className="no-gutters no-padding">
                            <p className="paragraph-header--blue">We make it easy to find available listings using MyScan, the most comprehensive database of available sites and buildings in Virginia. With it, you can: </p>
                        </Col>
                        <Col xs={12} className="no-gutters no-padding">
                            <ul className="list-padding-left">
                                <li>Get real-time data on commercial properties for lease and sale, including office, warehouse, industrial, and greenfield sites</li>
                                <li>Analyze utility, zoning, and transportation access information</li>
                                <li>Generate detailed reports, including total workforce, average wages, and institutions of higher learning within a 60-minute drive</li>
                                <li>Compare properties and download details in either Excel or as a PDF </li>
                                <li>Discover which listings may be eligible for <a href="https://www.henrico.com/site-selectors/incentives" target="_blank;">incentives</a> to help you grow your business</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <iframe frameborder="0" height="850" width="1200" src="https://sites.vedp.org/henrico"></iframe>
                        {/* <GisPropertySearchEmbed /> */}
                    </Row>
                    <Row>
                        <Col>
                            <p></p>
                            <p>There’s never been a better time to lease or buy commercial property in Henrico. Browse our MyScan tool above for the latest available listings and <a href="https://www.henrico.com/about-us/contact-us" target="_self" rel="noopener noreferrer">let us know</a> how we can help you find your ideal location in Henrico.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
    );
};

export default HenricoPropertySearch;
