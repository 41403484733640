import React from "react";
import { Switch } from "react-router-dom";
import { DefaultLayout } from "./DefaultLayout";

import HomePage from "../../HomePage/HomePage";
import NotFoundPage from "../../NotFound/NotFound";

// Why Henrico
import WhyHenrico from "../../WhyHenrico/WhyHenrico";
import SwamStatement from "../../WhyHenrico/SwamStatement";
// import StrategicPlan from "../../WhyHenrico/StrategicPlan";
// import AnnualReport from "../../WhyHenrico/AnnualReport";
import ESG from "../../WhyHenrico/ESG";
import Covid19 from "../Covid19/Covid19";
import QualityOfLife from "../../WhyHenrico/QualityOfLife";


// Press Releases and Announcements
import PressReleasesAndAnnouncementsArchive from "../../PressReleasesAndAnnouncements/PressReleasesAndAnnouncementsArchive";
import SinglePressReleaseAndAnnouncement from "../../PressReleasesAndAnnouncements/SinglePressReleaseAndAnnouncement";

// Upcoming Events
import UpcomingEventsArchive from "../../UpcomingEvents/UpcomingEventsArchive";

// Henrico Now
import HenricoNowArchive from "../../HenricoNow/HenricoNowArchive";
import SingleHenricoNowArticle from "../../HenricoNow/SingleHenricoNowArticle";
import HenricoNowFeaturedArticles from "../../HenricoNow/HenricoNowFeaturedArticles";

// Property Now
import PropertyNowArchive from "../../HenricoNow/PropertyNowArchive";

// Media Coverage
import MediaCoverageArchive from "../../MediaCoverage/MediaCoverageArchive";

// HenricoTIMES
// import HenricoTIMESArchive from "../../HenricoTIMES/HenricoTIMESArchive";

// Videos
import VideoArchive from "../../Videos/VideoArchive";

// Webinars
import Webinars from "../../Webinars/WebinarsArchive";

// About Us
import OurTeam from "../../AboutUs/OurTeam";
import ContactUs from "../../AboutUs/ContactUs";
import Careers from "../../AboutUs/Careers";
import FindUs from "../../AboutUs/FindUs";
import MeetingMinutes from "../../AboutUs/MeetingMinutes";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

// Existing Business
import BusinessRetentionExpansion from "../../ExistingBusiness/BusinessRetentionAndExpansion";
import FinancialAssistance from "../../ExistingBusiness/FinancialAssistance";
import ExportingAssistance from "../../ExistingBusiness/ExportingAssistance";
import AdditionalResources from "../../ExistingBusiness/AdditionalResources";
import StartABusiness from "../../ExistingBusiness/StartABusiness";
import WorkforceAssistance from "../../ExistingBusiness/workforceAssistance";
import GrowYourBusiness from "../../ExistingBusiness/GrowYourBusiness";
import MajorEmployersLegacyCompanies from "../../ExistingBusiness/MajorEmployersAndLegacyCompanies";
import HenricoLegacyCompaniesForm from "../../ExistingBusiness/MajorEmployersAndLegacyCompaniesForm";
import ExistingBusinessesRequestVisit from "../../ExistingBusiness/ExistingBusinessesRequestVisit";
import BusinessFirstSurvey from "../../ExistingBusiness/BusinessFirstSurvey";

// Find A Property
import FapAvailablePropertySearch from "../../FindAProperty/FapAvailablePropertySearch";
// import GreenCity from "../../FindAProperty/GreenCity";
// import InnsbrookTechnologyZone from "../../FindAProperty/InnsbrookTechnologyZone";
import IndustrialProperties from "../../FindAProperty/IndustrialProperties";

// Featured Properties
import FeaturedPropertiesArchive from "../../FeaturedProperties/FeaturedPropertiesArchive";
import SingleFeaturedProperties from "../../FeaturedProperties/SingleFeaturedProperties";

// White Oak
import WhiteOakQuickFacts from "../../WhiteOak/WhiteOakQuickFacts";
// import WhiteOakTheSite from "../../WhiteOak/WhiteOakTheSite";
import WhiteOakInfrastructure from "../../WhiteOak/WhiteOakInfrastructure";
import WhiteOakBusinessClimate from "../../WhiteOak/WhiteOakBusinessClimate";
import WhiteOakLocation from "../../WhiteOak/WhiteOakLocation";
import TheSiteMap from "../../WhiteOak/the-site/TheSiteMap";

// Nap
// import RichmondNapPage from "../../RichmondNap/RichmondNapPage";

// Site Selectors
import SsAvailablePropertySearch from "../../SiteSelectors/SsAvailablePropertySearch";
import Taxes from "../../SiteSelectors/Taxes";
// import DemographicData from "../../SiteSelectors/DemographicData";
import Transportation from "../../SiteSelectors/Transportation";
import CostOfLiving from "../../SiteSelectors/CostOfLiving";
import DataDownloads from "../../SiteSelectors/Downloads";
import Utilities from "../../SiteSelectors/Utilities";
import Incentives from "../../SiteSelectors/Incentives";
import DataConnectivity from "../../SiteSelectors/DataConnectivity";

// Target Sectors
import CorporateRegionalHeadquarters from "../../TargetSectors/CorporateRegionalHeadquarters";
import AdvancedManufacturing from "../../TargetSectors/AdvancedManufacturing";
import FinanceAndInsurance from "../../TargetSectors/FinanceAndInsurance";
import HealthAndLifeSciences from "../../TargetSectors/HealthAndLifeSciences";
import SupplyChainManagement from "../../TargetSectors/SupplyChainManagement";
import ProfessionalServices from "../../TargetSectors/ProfessionalServices";
// import DataCenters from "../../TargetSectors/DataCenters";
import InternationalCompanies from "../../TargetSectors/InternationalCompanies";
import Semiconductors from "../../TargetSectors/Semiconductors";

// Newsletter Form
import SubscribeNewsletterError from "../Forms/SubscribeNewsletterError";
import SubscribeNewsletterSuccess from "../Forms/SubscribeNewsletterSuccess";

function Routes() {
  return (
    <Switch>
      <DefaultLayout exact path="/" component={HomePage} />
      <DefaultLayout
        exact
        path="/why-henrico"
        component={WhyHenrico}
        title="Why Henrico?"
      />
      <DefaultLayout
        exact
        path="/why-henrico/pro-business"
        render={(props) => <WhyHenrico {...props} subpage={"Pro-Business"} />}
      />
      <DefaultLayout
        exact
        path="/why-henrico/market-access"
        render={(props) => <WhyHenrico {...props} subpage={"Market Access"} />}
      />
      <DefaultLayout
        exact
        path="/why-henrico/skilled-workforce"
        render={(props) => (
          <WhyHenrico {...props} subpage={"Skilled Workforce"} />
        )}
      />
      <DefaultLayout
        exact
        path="/why-henrico/competitive-operating-costs"
        render={(props) => (
          <WhyHenrico {...props} subpage={"Competitive Operating Costs"} />
        )}
      />
      <DefaultLayout
        exact
        path="/why-henrico/infrastructure"
        render={(props) => <WhyHenrico {...props} subpage={"Infrastructure"} />}
      />
      <DefaultLayout
        exact
        path="/why-henrico/quality-of-life"
        render={(props) => (
          <WhyHenrico {...props} subpage={"Quality of Life"} />
        )}
      />
      <DefaultLayout
        exact
        path="/why-henrico/fast-facts"
        render={(props) => <WhyHenrico {...props} subpage={"Fast Facts"} />}
      />
      <DefaultLayout
        exact
        path="/why-henrico/swam-certification"
        component={SwamStatement}
      />
      {/* <DefaultLayout
        exact
        path="/why-henrico/strategic-plan"
        component={StrategicPlan}
      />
      <DefaultLayout
        exact
        path="/why-henrico/annual-report"
        component={AnnualReport}
      />       */}
      <DefaultLayout
        exact
        path="/why-henrico/esg"
        component={ESG}
      />
      <DefaultLayout
        path="/site-selectors/available-property-search"
        component={SsAvailablePropertySearch}
      />
      <DefaultLayout path="/site-selectors/taxes" component={Taxes} />
      <DefaultLayout
        path="/site-selectors/transportation"
        component={Transportation}
      />
      {/* <DefaultLayout
        path="/site-selectors/demographic-data"
        component={DemographicData}
      /> */}
      <DefaultLayout
        path="/site-selectors/cost-of-living"
        component={CostOfLiving}
      />
      <DefaultLayout path="/site-selectors/utilities" component={Utilities} />
      <DefaultLayout path="/site-selectors/incentives" component={Incentives} />
      <DefaultLayout
        path="/site-selectors/data-downloads"
        component={DataDownloads}
      />
      <DefaultLayout
        path="/site-selectors/data-connectivity"
        component={DataConnectivity}
      />
      <DefaultLayout
        path="/existing-business/workforce-assistance"
        component={WorkforceAssistance}
      />
      <DefaultLayout
        path="/existing-business/business-retention-expansion"
        component={BusinessRetentionExpansion}
      />
      <DefaultLayout
        path="/existing-business/financial-assistance"
        component={FinancialAssistance}
      />
      <DefaultLayout
        path="/existing-business/exporting-assistance"
        component={ExportingAssistance}
      />
      <DefaultLayout
        path="/existing-business/additional-resources"
        component={AdditionalResources}
      />
      <DefaultLayout
        path="/existing-business/start-a-business"
        component={StartABusiness}
      />
      <DefaultLayout
        path="/existing-business/grow-your-business"
        exact
        component={GrowYourBusiness}
      />
      <DefaultLayout
        path="/existing-business/grow-your-business/request-a-visit"
        component={ExistingBusinessesRequestVisit}
      />
      <DefaultLayout
        path="/existing-business/grow-your-business/business-first-survey"
        component={BusinessFirstSurvey}
      />
      <DefaultLayout
        path="/existing-business/major-employers-legacy-companies/legacy-company-form"
        component={HenricoLegacyCompaniesForm}
      />
      <DefaultLayout
        path="/existing-business/major-employers-legacy-companies"
        component={MajorEmployersLegacyCompanies}
      />
      <DefaultLayout
        path="/news/media-coverage"
        component={MediaCoverageArchive}
      />
      {/* <DefaultLayout
        path="/news/henricotimes"
        component={HenricoTIMESArchive}
      /> */}
      <DefaultLayout path="/news/videos" component={VideoArchive} />
      <DefaultLayout path="/news/webinars" component={Webinars} />
      <DefaultLayout path="/covid19" component={Covid19} />
      <DefaultLayout path="/why-henrico/qualityoflife" component={QualityOfLife} />
      <DefaultLayout
        path="/news/press-releases-and-announcements"
        exact
        component={PressReleasesAndAnnouncementsArchive}
      />
      <DefaultLayout
        path="/news/press-releases-and-announcements/:date/:title"
        component={SinglePressReleaseAndAnnouncement}
      />
      <DefaultLayout
        path="/news/upcoming-events"
        component={UpcomingEventsArchive}
      />
      <DefaultLayout path="/covid19" component={Covid19} />
      <DefaultLayout
        path="/news/henrico-now"
        exact
        component={HenricoNowFeaturedArticles}
      />
      <DefaultLayout
        path="/news/henrico-now/articles"
        component={HenricoNowArchive}
      />
      <DefaultLayout
        path="/news/henrico-now/:article"
        component={SingleHenricoNowArticle}
      />
      <DefaultLayout
        path="/news/property-now"
        component={PropertyNowArchive}
      />
      <DefaultLayout
        path="/find-a-property/available-property-search"
        component={FapAvailablePropertySearch}
      />
      <DefaultLayout
        path="/find-a-property/featured-properties"
        exact
        component={FeaturedPropertiesArchive}
      />
      <DefaultLayout
        path="/find-a-property/featured-properties/:featuredPropertyTitle"
        exact
        component={SingleFeaturedProperties}
      />
      {/* <DefaultLayout
        path="/find-a-property/greencity"
        exact
        component={GreenCity}
      /> */}
      {/* <DefaultLayout
        path="/find-a-property/innsbrook-technology-zone"
        exact
        component={InnsbrookTechnologyZone}
      /> */}
      <DefaultLayout
        path="/find-a-property/industrial-properties"
        exact
        component={IndustrialProperties}
      />
      <DefaultLayout
        path="/white-oak/quick-facts"
        component={WhiteOakQuickFacts}
      />
      <DefaultLayout path="/white-oak/location" component={WhiteOakLocation} />;
      {/* <DefaultLayout path="/white-oak/the-site" component={WhiteOakTheSite} /> */}
      <DefaultLayout
        path="/white-oak/infrastructure"
        component={WhiteOakInfrastructure}
      />
      <DefaultLayout
        path="/white-oak/business-climate"
        component={WhiteOakBusinessClimate}
      />
      {/* <DefaultLayout path="/richmond-nap" component={RichmondNapPage} /> */}
      <DefaultLayout path="/about-us/our-team" component={OurTeam} />
      <DefaultLayout path="/about-us/contact-us" component={ContactUs} />
      <DefaultLayout path="/about-us/careers" component={Careers} />
      <DefaultLayout path="/about-us/find-us" component={FindUs} />
      <DefaultLayout path="/about-us/meeting-minutes" component={MeetingMinutes} />
      <DefaultLayout path="/about-us/privacy" component={PrivacyPolicy} />
      <DefaultLayout
        path="/target-sectors/corporate-and-regional-headquarters"
        component={CorporateRegionalHeadquarters}
      />
      <DefaultLayout
        path="/target-sectors/advanced-manufacturing"
        component={AdvancedManufacturing}
      />
      <DefaultLayout
        path="/target-sectors/finance-and-insurance"
        component={FinanceAndInsurance}
      />
      <DefaultLayout
        path="/target-sectors/health-and-life-sciences"
        component={HealthAndLifeSciences}
      />
      <DefaultLayout
        path="/target-sectors/supply-chain-management"
        component={SupplyChainManagement}
      />
      <DefaultLayout
        path="/target-sectors/professional-services"
        component={ProfessionalServices}
      />
      {/* <DefaultLayout
        path="/target-sectors/data-centers"
        component={DataCenters}
      /> */}
      <DefaultLayout
        path="/target-sectors/international-companies"
        component={InternationalCompanies}
      />
      <DefaultLayout
        path="/target-sectors/semiconductors"
        component={Semiconductors}
      />
      <DefaultLayout
        path="/subscribe-newsletter-success"
        component={SubscribeNewsletterSuccess}
      />
      <DefaultLayout
        path="/subscribe-newsletter-error"
        component={SubscribeNewsletterError}
      />
      <DefaultLayout path="/thesitemap" component={TheSiteMap} />
      <DefaultLayout path="*" exact component={NotFoundPage} />
    </Switch>
  );
}

export default Routes;
