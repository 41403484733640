import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const FindAPropertyNav = (props) => {
    return (
        <div>
            <Navbar expand="xs" className="secondary-navabar">
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/find-a-property/available-property-search"
                >
                    Henrico Property Search
                </NavLink>
                {/* <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/find-a-property/featured-properties"
                >
                    Featured Properties
                </NavLink> */}
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/white-oak/quick-facts"
                >
                    White Oak Tech Park
                </NavLink>
                {/* <span className="secondary-nav-separator">•</span>                
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/find-a-property/greencity"
                >
                    GreenCity
                </NavLink>                 */}
                {/* <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/richmond-nap"
                >
                    Richmond NAP
                </NavLink> */}
                {/* <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/find-a-property/innsbrook-technology-zone"
                >
                    Innsbrook Technology Zone
                </NavLink> */}
                <span className="secondary-nav-separator">•</span>
                <NavLink
                    activeClassName="secondary-nav-link-active"
                    className="secondary-nav-link"
                    to="/find-a-property/industrial-properties"
                >
                    Industrial Properties in Henrico
                </NavLink>                 
            </Navbar>
        </div>
    );
}

export default FindAPropertyNav;