import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';

import "./TargetSectors.scss";
import "./InternationalCompanies.scss";

import DataCard from '../shared/DataCard/DataCard';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import austrlia from "../TargetSectors/images/Australia.png";
import austria from "../TargetSectors/images/austria.png";
import bermuda from "../TargetSectors/images/bermuda.png";
import brazil from "../TargetSectors/images/Brazil.png";
import canada from "../TargetSectors/images/Canada.png";
import China from "../TargetSectors/images/China.png";
import finland from "../TargetSectors/images/Finland.png";
import france from "../TargetSectors/images/France.png";
import germany from "../TargetSectors/images/Germnay.png";
import India from "../TargetSectors/images/India.png";
import Ireland from "../TargetSectors/images/Ireland.png";
import japan from "../TargetSectors/images/Japan.png";
import luxe from "../TargetSectors/images/luxembourg.png";
import netherlands from "../TargetSectors/images/netherlands.png";
import spain from "../TargetSectors/images/spain.png";
import swedan from "../TargetSectors/images/swedan.png";
import swizerlands from "../TargetSectors/images/swizerland.png";
import unitedKingdom from "../TargetSectors/images/UK.png";

const pageTitle = 'International Companies';

const cards = [
    {
        titleLine1: "Prime Location",
        titleLine2: "",
        text: 'between LA & London',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "Foreign Trade Zone #207",
        titleLine2: "",
        text: 'is located at Richmond International Airport (RIC).',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "70+ International Firms",
        titleLine2: "",
        text: 'operate in Henrico.',
        categoryColor: '66A7CF',
    }
]

const countries = [
    {
        "image": austrlia,
        "name": "AUSTRALIA",
        "companies": [
            {
                "title": "IFCO Systems",
                "type": "Transportation & Logistics"
            }
        ]
    },
    {
        "image": austria,
        "name": "AUSTRIA",
        "companies": [
            {
                "title": "Franz Haas Machinery - America",
                "type": "Bakery Machinery"
            }
        ],
    },
    {
        "image": bermuda,
        "name": "BERMUDA",
        "companies": [
            {
                "title": "James River Insurance Company",
                "type": "Specialty Insurance Brokerage"
            }
        ]
    },

    {
        "image": brazil,
        "name": "BRAZIL",
        "companies": [
            {
                "title": "Brazilian Best Granite",
                "type": "Installation of Granite & Marble Countertops",
            },
            {
                "title": "Kels USA",
                "type": "Electrical Contact"
            }
        ]
    },    
    {
        "image": canada,
        "name": "CANADA",
        "companies": [
            {
                "title": "Allied International Credit",
                "type": "Debt Collection/Accounts Receivable Services",
            },
            {
                "title": "Drytac Corporation",
                "type": "Dry Mounting Adhesives & Laminates",
            },
            {
                "title": "Interbake Foods, Inc.",
                "type": "Cookies & Crackers"
            }
        ]
    },
    {
        "image": China,
        "name": "CHINA",
        "companies": [
            {
                "title": "Uquality Automotive Corporation",
                "type": "Auto Parts Distribution Center"
            }
        ]
    },
    {
        "image": finland,
        "name": "FINLAND",
        "companies": [
            {
                "title": "KONE U.S.",
                "type": "Service and Sales of Elevators & Escalators"
            }
        ]
    },
    {
        "image": france,
        "name": "FRANCE",
        "companies": [
            {

                "title": "CDA-USA",
                "type": "Labeling, Filing & Capping Equipment",
            },
            {
                "title": "Infilco Degremont",
                "type": "Water & Wastewater Treatment Systems",
            },
            {
                "title": "Teleperformance",
                "type": "Customer Service, Support & Outsourcing",
            },
            {
                "title": "Polykon/Air Liquide",
                "type": "Manufacturer of Cosmetic and Pharmaceutical Ingredients"
            }
        ]
    },
    {
        "image": germany,
        "name": "GERMANY",
        "companies": [
            {
                "title": "Abakus Solar USA",
                "type": "Solar Energy Solutions"
            },
            {
                "title": "Allianz Global Assistance ",
                "type": "USA Travel Insurance & Assistance"
            },
            {
                "title": "AMK Drives & Controls Inc. ",
                "type": "Drive & Motion Control Manufacturer "
            },
            {
                "title": "Bizerba USA",
                "type": "Manufacturer of Retail Scales, Slicers, and Labeling Equipment",
            },
            {
                "title": "DB Schenker ",
                "type": "Transportation & Logistics",
            },
            {
                "title": "Delphin Technology",
                "type": "Industrial Measurement Equipment/Technology ",
            },
            {
                "title": "Hauni Richmond",
                "type": "Consultancy Services",
            },
            {
                "title": "Henkel Corporation",
                "type": "Adhesive Technologies",
            },
            {
                "title": "Independent Container Line Ltd.",
                "type": "Ocean Container Shipping",
            },
            {
                "title": "KSB, Inc.",
                "type": "Engineered & Standard Pumps",
            },
            {
                "title": "Lincare Inc. ",
                "type": "Respiratory Care Equipment",
            },
            {
                "title": "NBB Controls Inc.",
                "type": "Industrial Radio Remote Controls Sales",
            },
            {
                "title": "Ondal USA Inc.",
                "type": "Electromechanical Equipment",
            },
            {
                "title": "SEALPAC USA LLC",
                "type": "Industrial Food Packaging",
            },
            {
                "title": "Siemens Infrastructure/Cities",
                "type": "Building Automation & Control Systems",
            },
            {
                "title": "Zeller+Gmelin ",
                "type": "Printing Inks"
            }
        ]
    },
    {
        "image": India,
        "name": "INDIA",
        "companies": [
            {
                "title": "Aditya Birla Minacs",
                "type": "Power Infrastructure Components & Systems"
            }
        ]
    },
    {
        "image": Ireland,
        "name": "IRELAND",
        "companies": [
            {
                "title": "Anord Mardix",
                "type": "Electrical Engineering & Manufacturing",
            },
            {
                "title": "Eaton Electrical Corporation",
                "type": "Electrical Systems & Components"
            }
        ]
    },
    {
        "image": japan,
        "name": "JAPAN",
        "companies": [
            {
                "title": "Canon Business Solutions",
                "type": "Office Equipment & Business Services",
            },
            {
                "title": "Global Stainless Supply/Forging, Flanges and Fittings, LLC",
                "type": "Industrial Pipe & Pipe Components",
            },
            {
                "title": "Hitachi Data Systems",
                "type": "IT Services & Technology",
            },
            {
                "title": "Konica Minolta Business Solutions USA, Inc.",
                "type": "Enterprise Content Management, Technology Optimization & Cloud Services",
            },
            {
                "title": "San-J International, Inc.",
                "type": "Tamari & Soy Sauce",
            },
            {
                "title": "SMC Corporation of America",
                "type": "Pneumatic Technology"
            }
        ]
    },
    {
        "image": luxe,
        "name": "LUXEMBOURG",
        "companies": [
            {
                "title": "Fareva Group",
                "type": "Consumer Healthcare Products",
            },
            {
                "title": "Regus",
                "type": "Provider of Flexible Workspaces"
            }
        ]
    },
    {
        "image": netherlands,
        "name": "NETHERLANDS",
        "companies": [
            {
                "title": "Arcadis U.S.",
                "type": "Engineering Services",
            },
            {
                "title": "CEVA Logistics",
                "type": "Contract Logistics & Freight Management",
            },
            {
                "title": "Randstad USA",
                "type": "Staffing & Workforce Solutions",    
            },
            {
                "title": "Rose Holm",
                "type": "Manufacturing",    
            },
            {
                "title": "Ropeblock",
                "type": "Lifting and Rigging Hardware",    
            }              
        ]
    },
    {
        "image": spain,
        "name": "SPAIN",
        "companies": [
            {
                "title": "Grifols",
                "type": "Global Healthcare"
            }
        ]
    },
    {
        "image": swedan,
        "name": "SWEDEN",
        "companies": [
            {
                "title": "Alfa Laval, Inc.",
                "type": "Plate Heat Exchangers, High-Purity Pumps",
            },
            {
                "title": "Security & Security Services",
                "type": "Security & Related Services"
            }
        ]
    },
    {
        "image": swizerlands,
        "name": "SWITZERLAND",
        "companies": [
            {
                "title": "Adecco",
                "type": "Employment Services",
            },
            {
                "title": "Ajilon Professional Staffing",
                "type": "Employment Agency & Career Services",
            },
            {
                "title": "Leica Geosystems",
                "type": "Retail - Surveying, Engineering & Construction Equipment",
            },
            {
                "title": "Thomas & Betts Power Solutions",
                "type": "Electric Contractors",
            },
            {
                "title": "Schindler Elevator Corporation",
                "type": "Elevator Sales & Service"
            }
        ]
    },
    {
        "image": unitedKingdom,
        "name": "UNITED KINGDOM",
        "companies": [
            {
                "title": "AlphaSonics",
                "type": "Ultrasonic Cleaning Solutions",
            },
            {
                "title": "Altius Associates",
                "type": "Financing Consulting",
            },
            {
                "title": "Cerulean Inc.",
                "type": "Engineering Services ",
            },
            {
                "title": "Compare.com",
                "type": "Auto Insurance",
            },
            {
                "title": "Detectamet, Inc.",
                "type": "X-ray and Metal Detectable Products",
            },
            {
                "title": "Elephant Insurance",
                "type": "Finance, Insurance, Securities ",
            },
            {
                "title": "G4S Secure Solutions USA Inc. ",
                "type": "Security Guard & Patrol Services",
            },
            {
                "title": "Glasdon, Inc. ",
                "type": "Manufacturing Logistics",
            },
            {
                "title": "Molins Richmond Inc.",
                "type": "Tobacco Machinery",
            },
            {
                "title": "Power Distribution, Inc.",
                "type": "Power Protection Systems Distribution",
            },
            {
                "title": "Thompson, Siegel & Walmsley LLC",
                "type": "Investment Management Firm",
            },
            {
                "title": "Willis of Virginia",
                "type": "Insurance Agents, Brokers & Services"
            }
        ]
    },
]

const SupplyChainManagement = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col>
                        <p className="paragraph-header--blue">Henrico Global Business Gateway</p>
                        <p>Our innovative Henrico Global Business Gateway program provides support to established international companies looking to enter the U.S. market. Participating businesses can take advantage of the program’s resources to more easily navigate the financial, logistical, and legal components of launching a successful venture in the U.S.</p>
                    </Col>
                </Row>
                <Row className="international-row">
                    <Col lg={5} sm={12} md={4} xl={5} className="international-col1">
                        <img src="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/IMG_2768.png" class="international-image"></img>
                        <img src="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/IMG_2769.jpg" class="international-image"></img>
                    </Col>
                    <Col lg={7} sm={12} md={8} xl={7} className="international-col2">
                        <p className="paragraph-header--blue center-aligned international-header1">THE PATH TO SUCCESS IS SIMPLE IN HENRICO</p>
                        <p>Entering the global market can be daunting and complex. If you're seeking to establish and grow your business in the United States, you'll land softly in Henrico, where out Global Business Gateway program takes a personal and customized approach to providing the support you need along the way.</p>
                        <p className="paragraph-header--blue center-aligned international-header2">Henrico's Global Business Gateway is open to select companies establishing their first operation in the United States.</p><br/>
                        <p className="center-aligned maxwidth540">Henrico's Global Business Gateway goes the extra mile to give participants access to:</p>
                        <p>
                            <ul>
                                <li>Dedicated office space, at a reduced cost, for up to three staff in one of the East Coast's most vibrant co-working spaces</li>   
                                <li>Business consulting services, such as custom market research, Search Engine Optimization (SEO), and qualified domestic (U.S.) sales leads and strategy</li>  
                                <li>Critical professional services, such as legal counsel, immigration consulting, marketing, accounting, and real estate services</li>  
                                <li>Specialized workforce partners for recruiting and hiring staff</li>  
                                <li>Business development organizations and networking</li>  
                                <li>Startup community and capital/financing partners</li>  
                                <li>Tailored relocation assistance for company leadership or staff and their families</li>   
                            </ul>
                        </p>                        
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>For more information about the Henrico Global Business Gateway, contact Andrew Larsen at <a href="mailto:andrew@henrico.com">andrew@henrico.com</a>.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {/*<p className="paragraph-header--blue">Why choose Henrico for your international business?</p>*/}
                        <p className="paragraph-header--blue">Why choose Henrico for your international business?</p>
                    </Col>
                    <Col xs={12}>
                        <p>
                            Henrico is recognized throughout the world as an ideal location for international business development, relocation, and foreign direct investment. To discover more about how Henrico can support your business, download our convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/International+Marketing+%26+HGBG+One+Pager+9.6.24.pdf" target="_blank;" title="International Business Brochure PDF">International Business brochure</a>.
                        </p>
                    </Col>
                </Row>
                <Row className="card-row-three-cards international-logo">
                    {cards.map(card => {
                        return <Col className="card-column" xs={6} sm={6} md={6} lg={4}>
                            <DataCard
                                key={card.titleLine1}
                                cardClass={`${card.categoryColor}`}
                                titleLine1={card.titleLine1}
                                titleLine2={card.titleLine2}
                                text={card.text}
                                fontSize="0.9rem"
                                cardSize="large"
                            />
                        </Col>
                    })}
                </Row><br/><br/>
                {countries.map(country => {
                    return <Row key={country.name}>
                        <Col xs={2}></Col>
                        <Col lg={2} sm={2} md={2} xl={2}>
                            <img alt="" src={country.image}></img>
                        </Col>
                        <Col lg={8} sm={8} md={8} xl={8}>
                            <p className="member-name paragraph-header--blue">{country.name}</p>
                                {country.companies ? country.companies.map(company => {
                                    return <div key={company.title}>
                                        <p>{company.title}<br/>{company.type}</p>
                                    </div>
                                }) : ''}
                        </Col>
                    </Row>
                })}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default SupplyChainManagement;
