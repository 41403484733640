import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import './WhiteOakQuickFacts.scss'
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import WhiteOakHero from "./WhiteOakHero";
import ResourcesAndDownloads from "../shared/FindAProperty/ResourcesAndDownloads";
import WhiteOakNav from "../shared/Navs/WhiteOakNav";
import PageTitle from "../shared/PageTitle/PageTitle";

const pageTitle = "Quick Facts";

    const WhiteOakQuickFacts = () => {
        const [dataCategory, setDataCategory] = useState('robust');

        const handleClick = (e, dataCategory) => {
            setDataCategory(dataCategory);

            const cards = document.getElementsByClassName('category-cards');
            for (let card of cards) {
                card.classList.remove('active');
            }

            let clickedElement = e.target;
            clickedElement.classList.add('active');
        }

        return (
            <div>
                <WhiteOakHero/>
                <Container>
                    <WhiteOakNav/>
                    <PageTitle title={pageTitle}/>
                    <Row>
                        <Col>
                            <p>White Oak Technology Park is a master-planned, publicly owned industrial park located at the intersection of I-64 and I-295 in tax-friendly Henrico, Virginia. The 2,278-acre park boasts the extensive and reliable power, utility, and transportation infrastructure demanded by today's most advanced companies. White Oak Technology Park is one of the premier industrial developments on the East Coast of the U.S.</p><br></br>
                            <iframe width="100%" height="400" src="https://player.vimeo.com/video/731851296?h=1bf21007f4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={3}>
                            <Row>
                                <Col xs={12} sm={4} md={4} lg={12} className="category-card-columns">
                                    <div className="category-cards card-style active" onClick={(e) => handleClick(e, 'robust')}>Robust Infrastructure</div>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={12} className="category-card-columns">
                                    <div className="category-cards card-style" onClick={(e) => handleClick(e, 'central')}>Central Location</div>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={12} className="category-card-columns">
                                    <div className="category-cards card-style" onClick={(e) => handleClick(e, 'pro-business')}>Pro-Business Climate</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={12} lg={9}>
                            {dataCategory === 'robust' && 
                                <div>
                                    <Row className="dynamic-padding-title">
                                        <Col>
                                        <p>Electric, fiber, water, sewer, gas, and transportation networks will meet almost any
                                                need. </p>
                                        </Col>
                                    </Row>
                                    <Row className="dynamic-padding">
                                        <ul className="list-padded">
                                            <li><p>Served by a networked (dual feed) <b>230kV transmission system</b> with multiple distribution substations and 34.5kV circuit</p></li>
                                            <li><p><b>10 MGD</b> water and <b>13 MGD</b> sewer capacity throughout the park</p></li>
                                            <li><p>Natural gas system with high and intermediate pressures with <b>24 to 150 PSI on site</b></p></li>
                                            <li><p><b>Extensive fiber network</b>, including Comcast, Level 3 Communications, Lumos Networks, SummitIG, Verizon, Windstream Corp., Zayo Group & other providers</p></li>
                                        </ul>
                                    </Row>
                                </div>
                            }
                            {dataCategory === 'central' && 
                                <div>
                                    <Row className="dynamic-padding">
                                        <ul className="list-padded">
                                            <li><p>Quick access to I-64, I-295, I-95, I-85, and logistics hubs at Richmond International Airport and the Port of Virginia</p></li>
                                            <li><p>East Coast location with 55% of the U.S. population within 750 miles</p></li>
                                            <li><p>Established air, freight, and rail logistics infrastructure</p></li>
                                        </ul>
                                    </Row>
                                </div>
                            }
                            {dataCategory === 'pro-business' && 
                                <Row className="dynamic-padding">
                                    <ul className="list-padded">
                                        <li><p>Competitive tax rates - $.30/$100 on machinery & tools</p></li>
                                        <li><p>Deep, talented labor pool, including 90,000 yearly graduates from nearby universities</p></li>
                                        <li><p>Quality housing, schools, and amenities with a low cost of living – making for easy relocation</p></li>
                                        <li><p>Henrico is home to 7 Fortune 1000 Headquarters and hundreds of manufacturing, service, and distribution operations</p></li>
                                    </ul>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Container>
                <ResourcesAndDownloads/>
                <Container>
                    <NewsInsights/>
                </Container>
            </div>
    );
};

export default WhiteOakQuickFacts;