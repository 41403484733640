import { Col, Container, Row} from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/advanced-manufacturing-logos.png';
import LogosImage2 from './images/advanced-manufacturing-logos-2.png';
import "./TargetSectors.scss";
import Quote from "../shared/Quote/Quote.js";
import DataCard from '../shared/DataCard/DataCard';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import './Semiconductors.scss';

const pageTitle = 'Semiconductor Manufacturing Sites';

const quoteData = {
    copy: "“Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico from several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion.”",
    signature: "Lionel Genix, CEO & President, Seppic Inc., Air Liquide Healthcare Specialty Ingredients"
}

const cards = [
    {
        titleLine1: "Since",
        titleLine2: "1611",
        text: 'Henrico has been a center for commerce and trade.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "Reach Customers Quickly",
        titleLine2: "",
        text: 'More than 55% of the U.S. population and major manufacturing sites are located within 750 miles of Henrico.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "140M Pounds of Cargo",
        titleLine2: "",
        text: 'are handled annually at Richmond International Airport.',
        categoryColor: '66A7CF',
    }
]

const Semiconductors = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>When you’re searching for a premier location for semiconductor manufacturing in the U.S., take a good look at Henrico, Virginia. We know how to power your success because we’re already here. In fact, we have a rich history of supporting the industry that dates back to the 1990s. That’s when we invested $44 million to develop <a href="https://www.henrico.com/white-oak/quick-facts" target="_blank" rel="noopener noreferrer">White Oak Technology Park</a>, enticing Qimonda AG to build a massive, 1 million sq. ft. facility. Later in 2001, Infineon Technologies invested more than $1 billion on the campus to build a wafer fabrication facility. QTS eventually acquired the semiconductor site. So, come grow with us in Henrico. We’re ready for you on day one. Watch and discover more.</p>
                    <div className="featured-video" ><iframe width="484" height="484" src="https://www.youtube.com/embed/4_rnM-cLOy0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/192852-iStock-1220136527.png" width="100%" alt="Gloved hand holding a semiconductor microchip"/>
                    <br/><br/>
                    <p><h3>CHIPS and Science Act in Henrico </h3></p>
                    <p>President Joe Biden signed the CHIPS and Science Act of 2022 into law on August 9 and its implications are already far reaching for the future of chip manufacturing in Henrico. The legislation invests nearly $250 billion in semiconductor and scientific research & development. With our shovel-ready sites at White Oak Technology Park, we’re ready to take the lead in semiconductor manufacturing funding. We packed this master-planned, publicly owned industrial park with the power, utilities, and infrastructure necessary to advance your company. Highlights include:</p>
                    <ul>
                        <li>Dual feed 230kV transmission system with multiple distribution substations and 34.5kV circuit</li>
                        <li>10 MGD water and 13+ MGD sewer capacity, with pumpstations on site</li>
                        <li>Natural gas system with 24-150 PSI</li>
                        <li>Fast-track permitting</li>
                        <li><a href="https://www.dominionenergy.com/virginia/renewable-energy-programs/green-power" target="_blank" rel="noopener noreferrer">Dominion Energy Green Power</a> program matches up to 100% electricity use with renewable energy</li>
                    </ul>  
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/193644-iStock-1365973043.png" width="100%" alt="Cars, cargo containers, and cranes at port"/>
                    <br/><br/>
                    <p><h3>Robust Infrastructure for Semiconductor Manufacturing Sites</h3></p>
                    <p>fDi Intelligence named us the #2 location in the world for transport and warehousing strategy for several great reasons. One is that when you want to move your inventory quickly, we have the assets you need. While the pandemic weakened global supply chain networks, <a href="http://flyrichmond.com/" target="_blank" rel="noopener noreferrer">Richmond International Airport (RIC)</a> continued to soar with a record-setting cargo volume of nearly 160 million pounds. Business was surging at the <a href="https://portofvirginia.com/" target="_blank" rel="noopener noreferrer">Port of Virginia</a>, which uniquely owns and operates its terminals. In 2022, container volume growth spiked 25 percent in one year to make it the fastest growing port in the country. And in Henrico, we’re securing our water future for decades with <a href="https://henrico.us/projects/cobbs-creek-reservoir/" target="_blank" rel="noopener noreferrer">Cobb’s Creek Reservoir</a>, a publicly owned $280 million water project slated for completion by 2023. Infrastructure advantages include:</p>
                    <ul>
                        <li>RIC, a designated Foreign Trade Zone</li>
                        <li>Cobb’s Creek Reservoir, a 14.8 billion-gallon regional water supply project</li>
                        <li>Port of Virginia, set to be the deepest port on the East Coast by 2024</li>
                    </ul> 
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19378-iStock-968289874.png" width="100%" alt="Three people in front of a computer discussing a tech part"/>
                    <br/><br/>
                    <p><h3>Top Tech Pipeline</h3></p>
                    <p>In Henrico, you’ll always have fresh talent to drive innovation. Our home state of Virginia has the second highest concentration of tech workers in the country. We also have the <a href="https://businessfacilities.com/2022/07/virginia-leads-states-tech-talent-pipeline-cybersecurity-rankings/" target="_blank" rel="noopener noreferrer">top tech pipeline in the United States</a>, according to Business Facilities magazine. Our excellence in education is further strengthened by the hands-on learning happening at the VCU College of Engineering. The institution is investing deeply in its research & development programs with a $93 million <a href="https://egr.vcu.edu/about/locations-facilities/engineering-research-building/" target="_blank" rel="noopener noreferrer">Engineering Research Building</a>. And, thanks to two historically Black colleges nearby, you can continue to cultivate a culture of inclusion and fuel your innovation through diversity. </p>
                    <ul>
                        <li>#1 Best State for Higher Education (2021)</li>
                        <li>700,000+ available workforce talent</li>
                        <li>90,000+ yearly higher education graduates </li>
                    </ul> 
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19325-iStock-1360692228.png" width="100%" alt="Semiconductor wafer in machine" />
                    <br/><br/>
                    <p><h3>Low Costs for Semiconductor Wafer Fabrication </h3></p>
                    <p>When it comes to semiconductor site selection, if you choose Henrico you can save on operating costs without sacrificing talent and market access. And there is no better place to boost your bottom line than <a href="https://www.henrico.com/white-oak/infrastructure" target="_blank" rel="noopener noreferrer">White Oak Technology Park</a>. Our machinery & tools (M&T) effective rate is one of the lowest in the state. Companies that increase Virginia port volume and create jobs or make capital investments in an international trade facility may be eligible for a tax credit. Plus, our affordable real estate and stable taxes reduce overhead, which can accelerate business growth. </p>
                    <ul>
                        <li>$6.67 average per sq. ft. per year industrial rent rate</li>
                        <li>30¢ machinery & tools effective rate </li>
                        <li>85¢ per $100 of assessed value real estate tax </li>
                        <li>2.5% labor unionization rate</li>
                    </ul> 
                    </Col>                                       
                </Row>                           
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default Semiconductors;
