import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const TargetSelectorsNav = (props) => {   
        return (
            <div>
                <Navbar expand="xs" className="secondary-navabar">
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/corporate-and-regional-headquarters"
                    >
                        Corporate and Regional Headquarters
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/advanced-manufacturing"
                    >
                        Advanced Manufacturing
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/finance-and-insurance"
                    >
                        Finance &amp; Insurance
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/health-and-life-sciences"
                    >
                        Health &amp; Life Sciences
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/professional-services"
                    >
                        Professional Services
                    </NavLink>
                    {/* <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/data-centers"
                    >
                        Data Centers
                    </NavLink> */}
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/international-companies"
                    >
                        International
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/supply-chain-management"
                    >
                        Supply Chain Management
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/target-sectors/semiconductors"
                    >
                        Semiconductors
                    </NavLink>
                </Navbar>
            </div>
        );
}

export default TargetSelectorsNav;