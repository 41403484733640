import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/finance-insurance-logos.a2cf1c8b.png';
import "./TargetSectors.scss";
import Quote from "../shared/Quote/Quote.js";
import DataCard from '../shared/DataCard/DataCard';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import "./FinanceAndInsurance.scss";

const pageTitle = 'Financial Services & Insurance';

const quoteData = {
    copy: "As home to the headquarters of Markel, Allianz, and Elephant Auto Insurance, Henrico has cemented its status as a regional hub for businesses in the financial services and insurance sectors. From corporate banking to fintech to specialty markets, companies come here to thrive.<br><br>The bottom line is that they love our pro-business climate along with the way our region operates its finances. Minutes away lies the headquarters of the Federal Reserve Bank of Richmond, established to strengthen the economy and community of the Fifth District. We offer a progressive and responsive regulatory environment. And due to a long history of its own strong fiscal management, Henrico has preserved an AAA bond rating from Moody’s, Standard & Poor’s, and Fitch.",
    signature: "Mike Heaton. President, Markel Ventures"
}

const cards = [
    {
        titleLine1: "Federal Reserve Bank",
        titleLine2: "",
        text: 'The 5th district is located in Richmond.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "6% Corporate Tax Rate",
        titleLine2: "",
        text: 'has not increased since 1972.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "AAA Bond Rating",
        titleLine2: "",
        text: 'Henrico is one of only 47 localities in the U.S. with an AAA bond rating from three major national bond rating agencies.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "85¢/$100 Real Estate Tax Rate",
        titleLine2: "",
        text: 'has not increased since 1978.',
        categoryColor: '66A7CF',
    }
]

const FinanceAndInsurance = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>As home to the headquarters of Markel, Allianz, and Elephant Auto Insurance, Henrico has cemented its status as a regional hub for businesses in the financial services and insurance sectors. From corporate banking to fintech to specialty markets, companies come here to thrive. </p>
                    <p>The bottom line is that they love our pro-business climate along with the way our region operates its finances. 
                        Minutes away lies the headquarters of the Federal Reserve Bank of Richmond, 
                        established to strengthen the economy and community of the Fifth District. 
                        We offer a progressive and responsive regulatory environment. 
                        And due to a long history of its own strong fiscal management, 
                        Henrico has preserved an AAA bond rating from Moody’s, Standard &amp; Poor’s, and Fitch. 
                        Download our convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/HEN23001_Financial+Services+and+Insuarance_8.5x11_v6.pdf" target="_blank;" title="Financial Services and Insurance Brochure PDF">Financial Services brochure</a> to discover more reasons why Henrico is the best location for your business.
                    </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/site-selectors/cms/FinIns1.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Low Cost of Business</h3></p>
                    <p>In industries as heavily taxed and regulated as finance and insurance can be, companies come to Henrico to take advantage of our <a href="https://www.henrico.com/site-selectors/taxes" target="_blank" rel="noreferrer noopener">favorable tax rates</a>. In fact, our corporate income tax rate has stood at just 6% since 1972, and our real estate tax has only decreased since 1978 and now stands at $0.85/$100 of assessed value.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/site-selectors/cms/FinIns2.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Cloud Computing Power </h3></p>
                    <p>Perhaps nothing is more important to financial services and insurance companies than the protection of data. Safeguard your information with our world-class cloud-based service offerings.</p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/site-selectors/cms/FinIns3.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Built for Growth</h3></p>
                    <p>Our infrastructure is not only prepared for your business of today; we’re constantly cultivating it for your business of tomorrow. Our state-of-the-art fiber optic telecommunications offerings will keep you connected and your data racing across the globe and back – in mere milliseconds.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/site-selectors/cms/FinIns4.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Educated Labor Force</h3></p>
                    <p>In Henrico, you’ll gain access to a surplus of <a href="https://www.henrico.com/why-henrico/skilled-workforce" target="_blank" rel="noreferrer noopener">top talent</a> ready and eager for careers in finance. Drive your business success with recruits from VCU School of Business and the Robins School of Business at the University of Richmond, consistently ranked as one of the top undergraduate business schools in the nation. The Darden School of Business at University of Virginia, regularly ranked as a Top 15 business school in the U.S. and Top 30 in the world, is only one hour’s drive away.</p>
                    </Col>                                       
                </Row>    
                <Row> 
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p class="centered-body"><br/>Intrigued? Let’s talk about all the ways Henrico can help drive your business success.<br/><a href="https://www.henrico.com/about-us/contact-us" target="_blank" rel="noreferrer noopener">Reach out</a> to the Henrico EDA and put us to work for you today.<br/><br/></p>
                    </Col>
                </Row>           
                <Row className="card-row-four-cards">
                    {cards.map(card => {
                        return <Col className="card-column" xs={6} sm={6} md={6} lg={4} xl={3}>
                            <DataCard
                                key={card.titleLine1}
                                cardClass={`${card.categoryColor}`}
                                titleLine1={card.titleLine1}
                                titleLine2={card.titleLine2}
                                text={card.text}
                                fontSize="0.9rem"
                                pageClassName='am'
                                cardSize="large"
                            />
                        </Col>
                    })}
                </Row>
                <Row> 
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p class="centered-body2"><br/><br/>Companies with a Presence in Henrico</p>
                    </Col>
                </Row>                 
                <Row className="justify-content-center">
                    <img className="finance-insurance-logos" alt="logos" src="https://henrico-eda-site-assets.s3.amazonaws.com/site-selectors/cms/FinInsLogos.png" width="100%" />
                </Row>
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default FinanceAndInsurance;
