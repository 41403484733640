import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import "./UpcomingEventPosts.scss";

const UpcomingEvents = ({ posts, loading }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div>
      {posts.map(post => (
        // Use anchor_tag as the id for each event Row
        <Row key={post._id} id={post.anchor_tag}>
          <Col xs={12} sm={2}>
            <div className="card-event-date">
              <p className="card-event-month" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.calendarMonth) }} />
              <p className="card-event-day" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.calendarDay) }} />
              <p className="card-event-start-time" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.calendarTime) }} />
            </div>
          </Col>
          <Col xs={12} sm={10}>
            <p className="event-title paragraph-header--blue" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.title) }} />
            <p className="event-date">{post.date}</p>
            <p className="event-time" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.eventTime) }} />
            <p className="event-copy" dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(post.archive_copy)
            }} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default UpcomingEvents;
