import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./SecondaryNav.scss";

const NewsNav = (props) => {
        return (
            <div>
                <Navbar expand="xs" className="secondary-navabar">
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/news/press-releases-and-announcements"
                    >
                        Press Releases & Announcements
                    </NavLink>
                        <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/news/henrico-now"
                    >
                        Henrico NOW
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/news/property-now"
                    >
                        Property NOW
                    </NavLink>
                    <span className="secondary-nav-separator">•</span>
                    <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/news/videos"
                    >
                        Videos
                    </NavLink>
                        {/* <span className="secondary-nav-separator">•</span> */}
                    {/* <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/news/media-coverage"
                    >
                        Media Coverage
                    </NavLink>
                    <span className="secondary-nav-separator">•</span> */}
                    {/* <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/covid19"
                    >
                        Covid-19
                    </NavLink>
                    <span className="secondary-nav-separator">•</span> */}
                    {/* <NavLink
                        activeClassName="secondary-nav-link-active"
                        className="secondary-nav-link"
                        to="/news/henricotimes"
                    >
                        HenricoTIMES
                    </NavLink> */}

                </Navbar>
            </div>
        );
}

export default NewsNav;