import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import NewsInsights from "../shared/NewsInsights/NewsInsights";
import PageTitle from '../shared/PageTitle/PageTitle';
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from '../shared/Navs/NewsNav';
import UpcomingEventsPost from "./UpcomingEventsPost";
import Pagination from "../shared/Pagination/Pagination";
import axios from 'axios';
import './UpcomingEventsArchive.scss';
import { sortEvents } from "../shared/Utilities/Sort";
import { remoteData } from '../shared/Cms';

const UpcomingEventsArchive = () => {
  const { domain, section } = {
    domain: 'henrico',
    section: 'upcoming-events',
  };

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    remoteData({ domain, section })
      .then(data => {
        const events = sortEvents(data);
        setUpcomingEvents(events);
        setLoading(false);
  
        // Scroll to the hash (anchor) after data is loaded
        const scrollToAnchor = () => {
          const hash = window.location.hash;
          if (hash) {
            const element = document.querySelector(hash);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        };
  
        // Attempt to scroll immediately
        scrollToAnchor();
  
        // Also try scrolling after a short delay to ensure DOM is fully rendered
        const timeoutId = setTimeout(scrollToAnchor, 100);
  
        // Cleanup timeout to prevent memory leaks
        return () => clearTimeout(timeoutId);
      });
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = upcomingEvents.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  useEffect(() => {
    // Scroll to the top when page is changed
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div>
      <NewsHero />
      <Container>
        <NewsNav />
        <PageTitle title="Upcoming Events" />
        <UpcomingEventsPost posts={currentPosts} loading={loading} />
        {currentPosts.length > postsPerPage && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={upcomingEvents.length}
            paginate={paginate}
          />
        )}
        <NewsInsights />
      </Container>
    </div>
  );
};

export default UpcomingEventsArchive;
